import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import useSWR from 'swr';

import { apiUrls } from 'services/api-urls';
import { fetcher } from 'services/fetcher';
import {
  ENV_API_KEY_SYMFONY,
  ENV_COOKIE_DOMAIN,
  ENV_COOKIE_PATH,
} from 'settings/env';

const COOKIE_OPTIONS = {
  domain: ENV_COOKIE_DOMAIN || undefined,
  path: ENV_COOKIE_PATH,
  sameSite: 'lax',
} as CookieSetOptions;

/**
 * Never call directly this hook, get functions from CartProvider to prevent useEffect execution
 */
export default function usePrivilege(): UsePrivilegeType {
  const LOCAL_CODE_KEY = 'privilegeCode';
  const [isReady, setReady] = useState(false);
  // Update this code when upgrading react-cookie to use the `doNotParse` option (we could then remove the typeof condition)
  const [cookies, setCookie, removeCookie] = useCookies([LOCAL_CODE_KEY]);
  const localCode =
    typeof cookies.privilegeCode !== 'string' ? '' : cookies.privilegeCode;

  const abortControllerRef = useRef<AbortController | null>(null);
  const { data: defaultCode } = useSWR<string | undefined>(
    apiUrls.getDefaultPrivilege(),
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  // At the first time load website, we check the privilege code in local
  useEffect(() => {
    if (undefined !== defaultCode) {
      if (localCode && defaultCode !== localCode) {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        validatePrivilegeCode(localCode, {
          signal: abortController.signal,
        }).then(({ valid_code: isValid }) => {
          if (!abortController.signal.aborted) {
            updatePrivilegeCode(!isValid ? defaultCode : localCode);
            setReady(true);
          }
        });
      } else {
        updatePrivilegeCode(defaultCode);
        setReady(true);
      }
    }
  }, [defaultCode]);

  // This effect is called because when resetting the privilege code we need to set the default code instead when it is available.
  useEffect(() => {
    if (!localCode && defaultCode) {
      updatePrivilegeCode(defaultCode);
    }
  }, [localCode, defaultCode]);

  function updatePrivilegeCode(code: string): void {
    abortControllerRef.current?.abort(); // Prevent override the privilege code by default validation

    setCookie(LOCAL_CODE_KEY, code, {
      ...COOKIE_OPTIONS,
      maxAge: 172800, // 48 hours
    });
  }

  function resetPrivilegeCode(): void {
    abortControllerRef.current?.abort(); // Prevent override the privilege code by default validation

    removeCookie(LOCAL_CODE_KEY, COOKIE_OPTIONS);
  }

  async function validatePrivilegeCode(
    code: string,
    additionalOptions?: RequestInit
  ): Promise<PrivilegeValidateResponse> {
    const defaultResponse: PrivilegeValidateResponse = {
      valid_code: false,
      message: [],
    };

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': ENV_API_KEY_SYMFONY,
      },
      body: JSON.stringify({
        privilegeCode: code.toUpperCase(),
      }),
    };

    try {
      const response = await fetch(apiUrls.getPrivilegeValidate(), {
        ...requestOptions,
        ...additionalOptions,
      });
      if (!response.ok) return defaultResponse;
      return await response.json();
    } catch (error) {
      return defaultResponse;
    }
  }

  return {
    privilegeCode: isReady ? localCode : '',
    setPrivilegeCode: updatePrivilegeCode,
    updatePrivilegeCode,
    validatePrivilegeCode,
    resetPrivilegeCode,
  };
}

type UsePrivilegeType = {
  privilegeCode: string;
  setPrivilegeCode: (privilegeCode: string) => void;
  updatePrivilegeCode: (code: string) => void;
  validatePrivilegeCode: (code: string) => Promise<PrivilegeValidateResponse>;
  resetPrivilegeCode: () => void;
};

export type PrivilegeValidateResponse = {
  valid_code: boolean;
  message: string[];
};
