import { isFr } from 'services/currentCountry';
import {
  env,
  ENV_API_URL_CATALOG,
  ENV_API_URL_CHECKOUT,
  ENV_API_URL_COLISSIMO,
  ENV_API_URL_CONTACT,
  ENV_API_URL_CUSTOMER,
  ENV_API_URL_PRODUCT,
  ENV_API_URL_SEARCH,
  ENV_MEDIAS_URL_POPIN,
  ENV_MEDIAS_URL_PRODUCT_SHEET,
  ENV_TWICPICS_URL_MEDIA,
} from 'settings/env';

export const apiUrls = {
  getAcp: (searchKeyword: string): string => {
    return `${ENV_API_URL_CATALOG}/ACP/${searchKeyword}?ResponseFormat=JSON`;
  },
  getCart: (): string => {
    return `${ENV_API_URL_CHECKOUT}/cart/get`;
  },
  getCatalogRequest: (): string => {
    return ENV_API_URL_CUSTOMER + '/catalog/request';
  },
  getColissimoGenerateToken: (): string => {
    return `${ENV_API_URL_COLISSIMO}/generate-token`;
  },
  getCheckoutDelivery: (): string => {
    return `${ENV_API_URL_CHECKOUT}/cart/delivery/get`;
  },
  getCheckoutDeliveryUpdate: (): string => {
    return `${ENV_API_URL_CHECKOUT}/cart/delivery/update`;
  },
  getCheckoutCartMerchandising: (): string => {
    return `${ENV_API_URL_CHECKOUT}/cart/merchandising`;
  },
  getMediaPopin: (url: string): string => {
    return `${ENV_MEDIAS_URL_POPIN}${url}`;
  },
  getMediaPage: (url: string): string => {
    return `${env.ENV_MEDIAS_URL_PAGES}${url}`;
  },
  getPictureUrl: (url: string): string => {
    return `${ENV_TWICPICS_URL_MEDIA}${url}`;
  },
  getDefaultPrivilege: (): string => {
    return `${ENV_API_URL_CHECKOUT}/privilegecode`;
  },
  getListing: (pathname: string): string => {
    return `${env.ENV_API_URL_LISTING}/json${pathname}`;
  },
  getPrivilegeValidate: (): string => {
    return `${ENV_API_URL_CHECKOUT}/privilegecode/validate`;
  },
  getProductDynamic: (productUrl: string): string => {
    return `${ENV_API_URL_PRODUCT}/dynamic/json${productUrl}`;
  },
  getProductStatic: (productUrl: string): string => {
    return `${ENV_API_URL_PRODUCT}/static/json${
      productUrl.startsWith('/') ? productUrl : `/${productUrl}`
    }`;
  },
  getProductInfos: (sku: string, reference: string, size: string): string => {
    return `${ENV_API_URL_CATALOG}/product?sku=${sku}&catalogSku=${reference}&sizeLabel=${size}`;
  },
  getContactSubmit: (): string => {
    return ENV_API_URL_CONTACT + '/submit';
  },
  getCustomerExists: (): string => {
    return ENV_API_URL_CUSTOMER + '/exists';
  },
  getCustomerUpdate: (): string => {
    return ENV_API_URL_CUSTOMER + '/update';
  },
  getCustomerChangePassword: (): string => {
    return ENV_API_URL_CUSTOMER + '/update-password';
  },
  getCustomerChangeEmail: (): string => {
    return ENV_API_URL_CUSTOMER + '/update-email';
  },
  getCustomerChangeSecondaryAddress: (): string => {
    return ENV_API_URL_CUSTOMER + '/address/secondary/save';
  },
  getCustomerCreate: (): string => {
    return ENV_API_URL_CUSTOMER + '/create';
  },
  getCustomerForgotPassword: (): string => {
    return ENV_API_URL_CUSTOMER + '/forgotpassword';
  },
  getCustomerForgotNumber: (): string => {
    return ENV_API_URL_CUSTOMER + '/forgotcustomernumber';
  },
  getCustomerEvtOrders: (): string => {
    return ENV_API_URL_CUSTOMER + '/evt/orders/get';
  },
  getCustomerEvtOrderDetails(evtId: number): string {
    return ENV_API_URL_CUSTOMER + '/evt/order/details/get/' + evtId;
  },
  getCustomerOrders: (): string => {
    return ENV_API_URL_CUSTOMER + '/orders/get';
  },
  getCustomerOrderDetails(orderId: string): string {
    return ENV_API_URL_CUSTOMER + '/order/details/get/' + orderId;
  },
  getCustomerNewsletterUnsubscribe(): string {
    return ENV_API_URL_CUSTOMER + '/newsletter/unsubscribe';
  },
  getSearchApi: (searchKeyword: string): string => {
    return `${ENV_API_URL_SEARCH}/json/${
      isFr() ? 'Recherche' : 'Search'
    }/${searchKeyword}`;
  },
  getSearchProducts: (
    searchKeyword: string | undefined,
    filters: string,
    pageIndex: string
  ): string => {
    let urlParams = '';

    switch (true) {
      case !!filters && !pageIndex:
        urlParams = `?${filters.substring(1)}`;
        break;
      case !filters && !!pageIndex:
        urlParams = `?${pageIndex}`;
        break;
      case !!filters && !!pageIndex:
        urlParams = `?${filters.substring(1)}&${pageIndex}`;
    }

    return `${ENV_API_URL_CATALOG}/json/Search/${searchKeyword}${urlParams}`;
  },
  getProductSizeGuide: (sizeGuide: string): string => {
    return `${ENV_MEDIAS_URL_PRODUCT_SHEET}/${sizeGuide}.html`;
  },
  getOrderValidation: (): string => {
    return `${ENV_API_URL_CHECKOUT}/order/validate`;
  },
  getOrderConfirmation: (): string => {
    return `${ENV_API_URL_CHECKOUT}/order/get`;
  },
};
